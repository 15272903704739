import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { getFolderColor } from "../../constants/colors";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import FolderActionsBar from "../FolderActionsBar";
import CreateFolder from "../CreateFolder";
import { firestore } from "lib/firebase";

const useStyles = makeStyles((theme) => ({
  hero: {
    background:
      "linear-gradient(to bottom, rgba(207,231,231,0.32) 0%,rgba(255,255,255,1) 100%)",
    boxShadow: "0 0 2px 2px rgba(0,0,0,.2)",
    "& .inner": {
      position: "relative",
      minHeight: "30vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      color: theme.palette.common.white,
      borderBottomLeftRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      overflow: "hidden",
      "&:before": {
        content: '""',
        position: "absolute",
        background:
          "linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0.5) 100%)",
        top: 0,
        bottom: 0,
        left: 0,
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        height: 400,
      },
    },
    "& h5": {
      fontWeight: 600,
    },
  },
}));

const FolderCover = ({
  folder,
  sortKey,
  sortDir,
  onSort,
  currentView,
  changeView,
  selectionMode,
  toggleSelectionMode,
  onShare,
  onNotification,
  query,
  onQueryChange,
}) => {
  const classes = useStyles();
  const [showEdit, setShowEdit] = useState(false);

  const toggleEdit = () => {
    setShowEdit(!showEdit);
  };

  const updateFolder = async (values) => {
    values.date_last_accessed = new Date();
    await firestore.collection("Folders").doc(folder.id).update(values);
    toggleEdit();
  };

  return (
    <Box
      className={classes.hero}
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
    >
      {currentView !== 'map' && (
          <Container
              className="inner"
              style={{
                backgroundImage: `url(${folder.background_image_url})`,
                backgroundColor: getFolderColor(folder.background_color),
              }}
              maxWidth={"md"}
          >
            <Box marginBottom={1} zIndex={1}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={12} md="auto">
                  <Box display="flex" alignItems="center">
                    <Typography variant="h5" color="inherit">
                      {folder.name}
                    </Typography>
                    <IconButton
                        onClick={toggleEdit}
                        color={"inherit"}
                        size="small"
                        style={{ marginLeft: 8 }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Typography
                      variant="body2"
                      color="inherit"
                      style={{ fontWeight: 300 }}
                  >
                    Last changed:{" "}
                    {dayjs((folder.date_last_modified || folder.date_created).toDate()).format(
                        "MM/DD/YYYY, hh:mm A"
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box zIndex={1}>
              <Typography variant="body1" color="inherit">
                {folder.description}
              </Typography>
            </Box>
          </Container>
      )}
      <FolderActionsBar
        sortKey={sortKey}
        sortDir={sortDir}
        onSort={onSort}
        currentView={currentView}
        changeView={changeView}
        selectionMode={selectionMode}
        toggleSelectionMode={toggleSelectionMode}
        onShare={onShare}
        onNotification={onNotification}
        query={query}
        onQueryChange={onQueryChange}
      />
      {showEdit && (
        <CreateFolder
          onClose={toggleEdit}
          title="Edit Folder"
          folder={folder}
          onSave={updateFolder}
        />
      )}
    </Box>
  );
};

export default FolderCover;
