import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import SvgIcon from "@material-ui/core/SvgIcon";
import IconButton from "@material-ui/core/IconButton";
import MuiButton from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import Popover from "@material-ui/core/Popover";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
  bindPopover,
} from "material-ui-popup-state/hooks";
import Button from "components/Button";
import Colors, { getFolderColor, colorToLabel } from "constants/colors";
import { storage, auth, FieldValue } from "lib/firebase";
import uuid from "lib/uuid";
import last from "lodash/last";
import { ReactComponent as ImageIcon } from "assets/icons/image.svg";
import UnsplashSearch from "../UnsplashSearch";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  coverContainer: {
    height: 200,
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    "& .MuiButton-contained": {
      backgroundColor: "rgba(0,0,0,.3)",
    },
  },
  swatchPopover: {
    borderRadius: 30,
  },
  swatchContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  swatch: {
    width: 36,
    height: 36,
    borderRadius: "50%",
    marginRight: theme.spacing(0.6),
    marginLeft: theme.spacing(0.6),
    cursor: "pointer",
    color: "white",
    textAlign: "center",
    lineHeight: 3.5,
  },
  button: {
    color: theme.palette.primary.contrastText,
    "& .MuiSvgIcon-root": {
      opacity: 0.6,
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 0,
    "& #cover-img-input": {
      display: "none",
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
  },
}))(MuiDialogActions);

function FolderModal({ onClose, onSave, title, classes, folder }) {
  const popupState = usePopupState({ variant: "popover", popupId: "color" });
  const imgPopupState = usePopupState({ variant: "popover", popupId: "img" });
  const [values, setValues] = useState({
    color: folder?.background_color
      ? colorToLabel(folder.background_color)
      : "Yellow",
    name: folder?.name,
    description: folder?.description || "",
    is_shared: folder?.is_shared || false,
  });
  const [file, setFile] = useState();
  const [image, setImage] = useState(folder?.background_image_url);
  const [showUnsplash, setShowUnsplash] = useState(false);

  const onColorSelect = (key) => (e) => {
    values.color = key;
    setValues({ ...values });
    setImage();
    popupState.close();
  };

  const onFileSelect = (e) => {
    imgPopupState.close();
    const { files } = e.target;
    const f = files[0];
    setFile(f);
    if (f) {
      setImage(URL.createObjectURL(f));
      setValues({
        ...values,
        color: null,
      });
    }
  };

  const onInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (name === "is_shared") {
      value = checked;
    }
    setValues({
      ...values,
      [name]: value,
    });
  };

  const onImageSelect = (item) => {
    setShowUnsplash(false);
    if (item) {
      setImage(item.urls.regular);
      setValues({
        ...values,
        color: null,
      });
    }
  };

  const handleSave = async () => {
    const user = auth.currentUser;
    const payload = {
      name: values.name,
      description: values.description,
      is_shared: values.is_shared,
      schema_version: 1,
      user_id: user.uid,
    };

    if (file) {
      const fileid = uuid();
      const ext = last(file.name.split("."));
      const name = fileid + "." + ext;
      const ref = await storage.ref(`user-content/${user.uid}/folder-images`);
      const result = await ref.child(name).put(file);
      payload.background_image_url = await result.ref.getDownloadURL();
    } else if(image) {
      payload.background_image_url = image;
    }
    if (values.color) {
      payload.background_color = "FolderColor" + values.color;
      payload.background_image_url = null;
    }
    payload.date_last_modified = FieldValue.serverTimestamp();
    if (!folder) {
      payload.date_created = FieldValue.serverTimestamp();
    }
    return onSave(payload);
  };

  return (
    <Dialog
      onClose={onClose}
      disableBackdropClick
      open={true}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent dividers>
        <Box
          className={classes.coverContainer}
          style={{
            backgroundColor: Colors[values.color],
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              {...bindTrigger(popupState)}
              startIcon={<ColorLensIcon />}
            >
              Color
            </Button>
            <Popover
              classes={{
                paper: classes.swatchPopover,
              }}
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box display="flex" className={classes.swatchContainer}>
                {Object.keys(Colors).map((key) => (
                  <Box
                    className={classes.swatch}
                    onClick={onColorSelect(key)}
                    key={key}
                    style={{
                      backgroundColor: Colors[key],
                    }}
                  >
                    {values.color === key && <CheckIcon />}
                  </Box>
                ))}
              </Box>
            </Popover>
            <MuiButton
              {...bindTrigger(imgPopupState)}
              className={classes.button}
              color="primary"
              variant="contained"
              startIcon={<ImageIcon />}
            >
              Image
            </MuiButton>
            <Menu
              {...bindMenu(imgPopupState)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <MenuItem component="label" htmlFor="cover-img-input">
                <input
                  id="cover-img-input"
                  accept="image/*"
                  style={{ display: "none" }}
                  type="file"
                  onChange={onFileSelect}
                />
                <ListItemIcon>
                  <CloudUploadOutlinedIcon color="primary" />
                </ListItemIcon>
                Upload Image
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setShowUnsplash(true);
                  imgPopupState.close();
                }}
              >
                <ListItemIcon>
                  <SvgIcon color="primary">
                    <ImageIcon />
                  </SvgIcon>
                </ListItemIcon>
                Select from Gallery
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box padding={2}>
          <TextField
            fullWidth
            name="name"
            margin="normal"
            placeholder="Enter Folder Name..."
            InputProps={{
              disableUnderline: true,
            }}
            value={values.name}
            onChange={onInputChange}
          />
          <TextField
            multiline
            fullWidth
            margin="normal"
            rows={3}
            label="Folder Description (optional)"
            placeholder="Enter a description"
            InputProps={{
              disableUnderline: true,
            }}
            name="description"
            value={values.description}
            onChange={onInputChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={values.is_shared}
              onChange={onInputChange}
              name="is_shared"
            />
          }
          label="Public Folder"
        />
        <Button
          autoFocus
          variant="contained"
          onClick={handleSave}
          color="primary"
          disabled={!values.name}
        >
          {folder ? "Save Changes" : "Create Folder"}
        </Button>
      </DialogActions>
      {showUnsplash && (
        <UnsplashSearch
          onClose={onImageSelect}
        />
      )}
    </Dialog>
  );
}

export default withStyles(styles)(FolderModal);
