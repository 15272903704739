import React from "react";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    inputRoot: {
    borderRadius: 4,
        paddingTop: '8px !important',
        paddingBottom:'8px !important',
  },
  input: {
    // padding: 12,
  },
}));

const MyChip = (props) => {
    return (
        <Chip
            color="primary"
            {...props}
        />
    );
};

export default function Tags({ options, tags, onChange, size, variant = 'filled' }) {
  const classes = useStyles();

  const handleChange = (e, value, reason) => {
      onChange(value);
  };

  return (
      <FormControl fullWidth margin="dense" size={size}>
          <Autocomplete
              fullWidth
              multiple
              filterSelectedOptions
              freeSolo
              size={size}
              onChange={handleChange}
              renderOption={(option) => option}
              options={options}
              value={tags}
              getOptionSelected={(option, value) => option === value}
              renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                      <MyChip {...getTagProps({ index })} label={option} size={size} />
                  ));
              }}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      variant={variant}
                      placeholder="Enter optional tags"
                      InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          classes: {
                              input: classes.input,
                              root: classes.inputRoot,
                          },
                      }}
                  />
              )}
          />
      </FormControl>
  );
}
