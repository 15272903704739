import { useEffect, useState, useContext } from "react";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import store from "store";
import { firestore, FieldValue } from "lib/firebase";
import orderBy from "lodash/orderBy";

const stringSorter = (key) => (obj) => {
  const val = obj[key];
  if (typeof val === "string")
    return val
      .replace(
        /[^\x00-\x7F]/g, // remove non ascii/emoji
        ""
      )
      .toLowerCase();
  return val;
};

export default (folderId, userId) => {
  const [sort, setSort] = useState({
    key: store.get("sort_key") || "name",
    dir: store.get("sort_dir") || "asc",
  });

  const [folder, loadingFolder, folderLoadError] = useDocumentData(
    folderId && firestore.collection("Folders").doc(folderId),
    { idField: "id" }
  );
  const [
    folders,
    loadingFolders,
    foldersLoadingError,
  ] = useCollectionData(
    firestore
      .collection("Folders")
      .where("parent_id", "==", folderId)
      .where("user_id", "==", userId),
    { idField: "id" }
  );
  const [
    docs,
    loadingDocs,
    docsLoadingError,
  ] = useCollectionData(
    firestore
      .collection("Notes")
      .where("folder_id", "==", folderId)
      .where("user_id", "==", userId),
    { idField: "id" }
  );

  useEffect(() => {
    if (folderId) {
      firestore.collection("Folders").doc(folderId).update({
        date_last_accessed: FieldValue.serverTimestamp(),
      });
    }
  }, [folderId]);

  const allItems = [];

  if (docs) {
    allItems.push(...docs.map((d) => {
      d._type = "note";
      return d;
    }))
  }
  if (folders) {
    allItems.push(...folders.map((f) => {
      f._type = "folder";
      return f;
    }))
  }

  const sortedItems = orderBy(allItems, stringSorter(sort.key), [sort.dir]);

  const onSort = (key) => {
    const newDir = sort.dir === "asc" ? "desc" : "asc";
    setSort({ key, dir: newDir });
    store.set("sort_key", key);
    store.set("sort_dir", newDir);
  };

  return {
    sortKey: sort.key,
    sortDir: sort.dir,
    onSort,
    folder,
    loadingFolder,
    notes: docs,
    folders: folders,
    sortedItems,
    loadingItems: loadingDocs || loadingFolders,
  };
};
