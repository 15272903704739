import React, { useContext, useState, useEffect, useMemo } from "react";
import Helmet from "react-helmet/es/Helmet";
import qs from 'querystring';
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Loading from "components/Loading";
import AppBar from "components/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import SessionContext from "context/session";
import { firestore, FieldValue } from "lib/firebase";
import useSnack from "hooks/useSnack";
import NoteTypes from "constants/noteTypes";
import NoteTitleInput from "./components/NoteTitleInput";
import NoteDescriptionInput from "./components/NoteDescriptionInput";
import NoteTypeText from "./components/NoteTypeText";
import NoteTypeAudio from "./components/NoteTypeAudio";
import NoteTypeVideo from "./components/NoteTypeVideo";
import NoteTypeDocument from "./components/NoteTypeDocument";
import NoteTypePhoto from "./components/NoteTypePhoto";
import Tags from "./components/TagsInput";
import Footer from "./components/Footer";
import buildNoteContentObject from 'utils/buildNoteContentObject';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    marginBottom: 80,
  },
  expanded: {
    margin: 0,
  },
  chip: {
    borderRadius: 6,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    marginRight: 8,
    fontWeight: 700,
    height: 28,
  },
}));


const getNoteValue = (type, content) => {
  switch (type) {
    case NoteTypes.Text:
      return content.text.html_text;
    case NoteTypes.Photo:
      return content.photos.map(i => i.image);
    case NoteTypes.Audio:
      return content.audio.audio;
    case NoteTypes.Video:
      return content.video.video;
  }
};

const NoteEditor = ({
  match: {
    params: { folderId, type, id },
  },
  history,
  location,
}) => {
  const classes = useStyles();
  const snack = useSnack();
  const { user } = useContext(SessionContext);
  const [values, setValues] = useState({
    name: "",
    description: "",
    value: "",
    tags: [],
    is_shared: false,
    note_type: type,
  });

  useEffect(() => {
    if (id) {
      firestore.collection('Notes').doc(id).get().then(
          (snap) => {
            if (snap.exists) {
              const doc = snap.data();
              setValues({
                name: doc.name,
                description: doc.description,
                value: getNoteValue(doc.note_type, doc.content),
                tags: doc.tags,
                is_shared: doc.is_shared,
                note_type: doc.note_type,
              });
            } else {
              snack.error('Document not found');
              history.push(`/`);
            }
          },
          (e) => {
            snack.error(e.message);
          }
      )
    } else {
      const query = qs.parse(location.search.substr(1));
      const { title, description, text } = query;
      // timeout hack for ck editor
      setTimeout(() => {
        setValues({
          ...values,
          name: title,
          description,
          value: text,
        });
      },0);
    }
  }, []);

  const updateUserTags = async () => {
    await firestore
      .collection("Users")
      .doc(user.id)
      .update({
        tags: FieldValue.arrayUnion(...values.tags),
      });
  };

  const saveNote = async () => {
    const payload = {
      schema_version: 2,
      content: buildNoteContentObject(values.note_type, values.value),
      folder_id: folderId || null,
      date_last_modified: new Date(),
      date_last_accessed: new Date(),
      user_id: user.id,
      is_shared: values.is_shared,
      tags: values.tags,
      note_type: values.note_type,
      description: values.description,
      name: values.name,
    };

    console.log(payload)

    if (!id) {
      payload.date_created = new Date();
    }

    const ref = firestore.collection("Notes").doc(id);
    await ref.set(payload, { merge: true });
    await updateUserTags();

    if (!id) {
      snack.success('Note created!');
      const redirect = folderId
        ? `/folders/${folderId}`
        : `/`;

      history.replace(redirect);
    } else {
      snack.success('Note saved!');
    }
  };

  const Component = useMemo(() => {
    switch (values.note_type) {
      case NoteTypes.Text: return NoteTypeText;
      case NoteTypes.Audio: return NoteTypeAudio;
      case NoteTypes.Video: return NoteTypeVideo;
      case NoteTypes.Photo: return NoteTypePhoto;
      case NoteTypes.Document: return NoteTypeDocument;
      default:
        return Box;
    }
  }, [values.note_type]);

  return (
    <Box>
      <Helmet title={id ? "Edit Note" : "Create Note"} />
      <AppBar parent={folderId} />
      <Container maxWidth="md" className={classes.container}>
        <NoteTitleInput
          value={values.name}
          onChange={(e) => setValues({ ...values, name: e.target.value })}
        />
        <NoteDescriptionInput
          value={values.description}
          onChange={(e) =>
            setValues({ ...values, description: e.target.value })
          }
        />
        <Box marginTop={2} marginBottom={2}>
          <Component
            value={values.value}
            onChange={(data) => {
              setValues((prev) => ({
                ...prev,
                value: data
              }));
            }}
          />
        </Box>
        <Tags
          options={user.tags || []}
          tags={values.tags}
          onChange={(val) => {
            setValues({
              ...values,
              tags: val,
            });
          }}
        />
      </Container>
      <Footer
        disabled={!values.value || !values.name}
        onCancel={() => history.goBack()}
        onSave={saveNote}
        isShared={values.is_shared}
        onToggleShare={(shared) => setValues({ ...values, is_shared: shared })}
        saveLabel={id ? 'Save' : 'Done'}
      />
    </Box>
  );
};

export default NoteEditor;
