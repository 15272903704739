import React from 'react';
import Box from '@material-ui/core/Box';
import Helmet from "react-helmet/es/Helmet";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AppBar from "../../components/AppBar";

const Extension = () => {
    return (
        <Box>
            <Helmet title="Browser Extension" />
            <AppBar isRoot />
            <Container maxWidth="md" style={{ marginTop: 24 }}>
                <Typography
                    gutterBottom
                    style={{ fontWeight: 700 }}
                    variant="h5"
                >
                    Browser Extension
                </Typography>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h6">Something</Typography>
                        <Typography>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.


                        </Typography>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    )
}

export default Extension;