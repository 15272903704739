const ACCEPTS = [".jpg", ".jpeg", ".gif", ".png", ".bmp", ".pdf", "video/", "audio/"];


const isValidFile = (file) => {
  return ACCEPTS.some(x => {
      if (x.includes("/")) {
          const [type] = x.split("/");
          return file.type.startsWith(type);
      } else {
          return file.name.endsWith(x);
      }
  });
};

export default isValidFile;
