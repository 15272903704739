import React, { useState, useContext, useMemo } from "react";
import { fade, withStyles, makeStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore } from "lib/firebase";
import SessionContext from "context/session";
import Button from "../Button";
import Loading from "../Loading";
import { createDataTree } from 'utils';

const useStyles = makeStyles((theme) => ({
  treeItem: {
    // '&:focus': {
    //     backgroundColor: theme.palette.primary.light,
    //     "& .MuiTreeItem-label": {
    //       backgroundColor: 'transparent !important',
    //     },
    // },
    "& .MuiTreeItem-label": {
      padding: 8,
    },
  },
  treeSelected: {
    // backgroundColor: theme.palette.primary.light,
    // "& .MuiTreeItem-label": {
    //   backgroundColor: "transparent !important",
    // },
    '& :focus': {
        "& .MuiTreeItem-label": {
            backgroundColor: "transparent !important",
        },
    }
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {children}
      </Box>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& .MuiDivider-root": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    justifyContent: "flex-start",
  },
}))(MuiDialogActions);

export default function MoveDialog({ count, onClose, onMove }) {
  const [selected, setSelected] = useState('');
  const { user } = useContext(SessionContext);
  const classes = useStyles();

  const [
    folders,
    loadingFolders,
    foldersLoadingError,
  ] = useCollectionData(
    firestore.collection("Folders").where("user_id", "==", user.id),
    { idField: "id" }
  );

  const onNodeSelect = (e, id) => {
      setSelected(id);
  }

  const onSave = async () => {
      await onMove(selected);
  };

  const data = useMemo(() => {
    if (!folders) return [];
    return createDataTree(folders);
  }, [folders]);

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      classes={{
          root: classes.treeItem,
        selected: classes.treeSelected,
      }}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>
        <Typography variant="h6">Move {count} Notes to Folder...</Typography>
      </DialogTitle>
      <DialogContent dividers>
        {loadingFolders ? (
          <Loading />
        ) : (
          <div>
            <TreeView
                selected={selected}
                onNodeSelect={onNodeSelect}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {data.map((i) => renderTree(i))}
            </TreeView>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onSave}
          color="primary"
          disabled={!selected}
        >
          {selected ? `Move to ${folders.find(i => i.id === selected).name}` : "Select destination"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
