import padStart from 'lodash/padStart';

export const milisecondsToPretty = (ms) => {
    const sec = Math.round(ms / 1000);
    if (sec < 60) return `00:${padStart(sec, 2, 0)}`;
    const m = Math.floor(sec / 60);
    const s = sec % 60;
    return `${padStart(m, 2, 0)}:${padStart(s, 2, 0)}`;
};

export const createDataTree = (dataset) => {
    const hashTable = Object.create(null);
    dataset.forEach(
        (aData) => (hashTable[aData.id] = { ...aData, children: [] })
    );
    const dataTree = [];
    dataset.forEach((aData) => {
        if (aData.parent_id) {
            const parent = hashTable[aData.parent_id];
            if (parent) {
                hashTable[aData.parent_id].children.push(hashTable[aData.id]);
            }
        } else {
            dataTree.push(hashTable[aData.id]);
        }
    });
    return dataTree;
};