import React, { useEffect, useRef } from 'react';
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import {useDownloadURL} from "react-firebase-hooks/storage";
import {storage} from "lib/firebase";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from '@material-ui/core/Button';

const UnsupportedDocument = ({ doc }) => {
    const [url, loading] = useDownloadURL(
        storage.ref(`/user-content/${doc.user_id}/document/${doc.content.file_id}`)
    );

    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5">Unsupported Document</Typography>
                <Typography paragraph>
                    Sorry, this file format is not supported. You can still download the file and open in your device.
                </Typography>
                <Button variant="contained" color="primary" component="a" href={url}>Download</Button>
            </CardContent>
        </Card>
    )
}

export default UnsupportedDocument;